import React from "react";
import { Col, Row, Select } from "antd";
import styled from "styled-components";
import i18next from "i18next";

type Props = {
  distributors: Distributor[];
  isLoading: boolean;
  setSelectedDistributorId: React.Dispatch<
    React.SetStateAction<Distributor["id"]>
  >;
};

const DistributorSelector = ({
  distributors,
  isLoading,
  setSelectedDistributorId,
}: Props) => {
  return (
    <Row style={{ marginBottom: 15, width: "100%" }}>
      <Col span={6}>
        <Label>{i18next.t("distributor_name")}</Label>
        <Select
          style={{ minWidth: 200 }}
          placeholder={i18next.t("select_distributor")}
          loading={isLoading}
          showSearch
          optionFilterProp="label"
          onChange={(value) => {
            setSelectedDistributorId(value);
          }}
          options={distributors?.map((distributor) => ({
            label: distributor.officialDistributorName || i18next.t("no_name"),
            value: distributor.id,
          }))}
        />
      </Col>
    </Row>
  );
};

export default DistributorSelector;

const Label = styled.p`
  color: #6d6d6d;
  font-size: 12px;
  line-height: 20px;
`;
