import React, { useMemo, useState } from "react";

import dayjs, { Dayjs } from "dayjs";

import {
  useCalculateOrder,
  useGetOrder,
  useSaveOrder,
} from "api/reactQuery/queryComponents";
import {
  BSCoreServicesDtoMarketplaceOrderDto,
  BSCoreServicesDtoMarketplaceOrderProductDto,
  BSCoreWebRequestsResponsesDistributorsDistributorDepotResponse,
  BSCoreWebRequestsResponsesDistributorsDistributorResponse,
  BSDALMarketplaceEnumsOrderStatus,
} from "api/reactQuery/querySchemas";
import OrderInfoModal from "components/MpOrder/CreateOrder/OrderInfoModal";
import OrderInfoSection from "components/MpOrder/CreateOrder/OrderInfoSection";
import OrderProductsTabs from "components/MpOrder/CreateOrder/OrderProductsTab/OrderProductsTabs";
import OrderTotals from "components/MpOrder/OrderTotals";
import { dateFormatForBE } from "components/Utils/dayjsUtils";
import { MarketplaceOrderStatuses } from "components/Utils/enums";

const CreateOrder: React.FC = () => {
  const [selectedDistributor, setSelectedDistributor] =
    useState<BSCoreWebRequestsResponsesDistributorsDistributorResponse>();
  const [selectedDepot, setSelectedDepot] =
    useState<BSCoreWebRequestsResponsesDistributorsDistributorDepotResponse>();
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState<Dayjs>();
  const [orderInfoModalData, setOrderInfoModalData] = useState<{
    open: boolean;
    status:
      | BSDALMarketplaceEnumsOrderStatus.InProgress
      | BSDALMarketplaceEnumsOrderStatus.New;
  }>();
  const [showAllProductsAvailability, setShowAllProductsAvailability] =
    useState(false);
  const [
    showMultipackProductsAvailability,
    setShowMultipackProductsAvailability,
  ] = useState(false);
  const [
    showOnInvoiceProductsAvailability,
    setShowOnInvoiceProductsAvailability,
  ] = useState(false);

  const { data: initialOrderResponse, isLoading: initialOrderLoading } =
    useGetOrder(
      {
        queryParams: { depotId: selectedDepot?.id },
      },
      {
        enabled: !!selectedDepot?.id && !!selectedDistributor?.id,
        onSuccess: (data) => {
          resetCalculatedData();
          resetSavedOrderResponse();
          checkProductAvailability(data);
        },
      }
    );

  const {
    mutate: calculateOrder,
    data: calculatedOrderResponse,
    reset: resetCalculatedData,
  } = useCalculateOrder({});

  const {
    data: savedOrderResponse,
    mutate: saveOrder,
    isLoading: isSaveOrderLoading,
    reset: resetSavedOrderResponse,
  } = useSaveOrder({
    onSuccess: (data) => {
      checkProductAvailability(data);
      if (data?.status !== undefined && data?.status !== null) {
        setOrderInfoModalData({
          open: true,
          status: MarketplaceOrderStatuses[data?.status],
        });
      }
    },
  });
  const checkProductAvailability = (
    data: BSCoreServicesDtoMarketplaceOrderDto
  ) => {
    const allProducts = data.products.filter(
      (product) => !product.isInvoiceProduct
    );
    const onInvoiceProducts = data.products.filter(
      (product) => product.isInvoiceProduct
    );
    const multipackProducts = data.products.filter(
      (product) => product.isMultiPack
    );
    const shouldShowProductAvailability = (
      products: BSCoreServicesDtoMarketplaceOrderProductDto[]
    ) =>
      products.some(
        (product) =>
          product.currentAvailableUnits !== undefined &&
          product.currentAvailableUnits !== null &&
          product.orderedQuantities &&
          product.orderedQuantities > product.currentAvailableUnits
      );

    setShowAllProductsAvailability(shouldShowProductAvailability(allProducts));
    setShowMultipackProductsAvailability(
      shouldShowProductAvailability(multipackProducts)
    );
    setShowOnInvoiceProductsAvailability(
      shouldShowProductAvailability(onInvoiceProducts)
    );
  };
  const onOrderInfoModalClose = async () => {
    resetCalculatedData();
    selectedDeliveryDate && setSelectedDeliveryDate(undefined);
    orderInfoModalData && setOrderInfoModalData(undefined);
  };

  const orderData: BSCoreServicesDtoMarketplaceOrderDto =
    calculatedOrderResponse || savedOrderResponse || initialOrderResponse;

  const isNewOrder =
    MarketplaceOrderStatuses[orderData?.status] ===
    BSDALMarketplaceEnumsOrderStatus.New;

  const deliveryDate: Dayjs = selectedDeliveryDate
    ? selectedDeliveryDate
    : !isNewOrder
      ? orderData?.deliveryDate && dayjs(orderData?.deliveryDate)
      : undefined;

  const areQuantitiesSelected = orderData?.products?.some(
    (product) => !!product.orderedQuantities
  );

  const isSaveAndSendDisabled = useMemo(
    () =>
      isSaveOrderLoading ||
      !deliveryDate ||
      !areQuantitiesSelected ||
      (calculatedOrderResponse
        ? !calculatedOrderResponse?.isTruckAreaFactorInLimit ||
          !calculatedOrderResponse.isTotalWeightInLimit
        : true),
    [
      calculatedOrderResponse,
      areQuantitiesSelected,
      deliveryDate,
      isSaveOrderLoading,
    ]
  );

  const onSaveAndSendHandler = () => {
    if (!orderData) return;

    saveOrder({
      body: {
        ...orderData,
        deliveryDate: dayjs(deliveryDate).format(dateFormatForBE),
        depotId: selectedDepot.id,
      },
    });
  };

  return (
    <>
      <OrderInfoSection
        initialOrderLoading={initialOrderLoading}
        resetCalculatedData={resetCalculatedData}
        selectedDepot={selectedDepot}
        setSelectedDepot={setSelectedDepot}
        selectedDistributor={selectedDistributor}
        setSelectedDistributor={setSelectedDistributor}
        deliveryDate={deliveryDate}
        setDeliveryDate={setSelectedDeliveryDate}
        saveAndSendDisabled={isSaveAndSendDisabled}
        saveAndSendLoading={isSaveOrderLoading}
        onSaveAndSend={onSaveAndSendHandler}
        dateCreated={
          orderData?.dateCreated &&
          dayjs(orderData?.dateCreated)?.add(
            dayjs(orderData?.dateCreated).utcOffset(),
            "minutes"
          )
        }
        showCountdown={!isNewOrder}
      />
      {!!orderData?.total && !!deliveryDate && (
        <OrderTotals
          totals={orderData.total}
          isTotalWeightInLimit={
            calculatedOrderResponse
              ? calculatedOrderResponse.isTotalWeightInLimit
              : true
          }
          isTruckAreaFactorInLimit={
            calculatedOrderResponse
              ? calculatedOrderResponse.isTruckAreaFactorInLimit
              : true
          }
        />
      )}
      {!!selectedDepot && orderData && !!deliveryDate && (
        <OrderProductsTabs
          showAllProductsAvailability={showAllProductsAvailability}
          showOnInvoiceProductsAvailability={showOnInvoiceProductsAvailability}
          showMultipackProductsAvailability={showMultipackProductsAvailability}
          enableEditOrderedQuantities={!!deliveryDate && !isSaveOrderLoading}
          orderData={orderData}
          calculateOrder={calculateOrder}
        />
      )}
      {orderInfoModalData && (
        <OrderInfoModal
          isOpen={orderInfoModalData.open}
          status={orderInfoModalData.status}
          onClose={onOrderInfoModalClose}
        />
      )}
    </>
  );
};
export default CreateOrder;
